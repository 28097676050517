<template>
  <div>
    <m-bread-crumb/>

<section class="contact spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="contact__text">
                        <div class="section-title">
                            <h2>Onde estamos?</h2>
                            <p>Encontre a nossa rede de atendimento e as nossas representações físicas.</p>
                        </div>
                        <ul>
                            <li><span>Dias úteis:</span> 08:00 as 15:00 horas </li>
                            <li><span>Sabados:</span> Encerrado </li>
                            <li><span>Domingo:</span> Encerrado</li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-6">
                    <div class="contact__form">
                        <form action="#">
                            <div class="row">
                                <div class="col-lg-6">
                                    <input type="text" placeholder="Nome">
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" placeholder="Email">
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" placeholder="Telefone">
                                </div>
                            </div>
                            <input type="text" placeholder="Subject">
                            <textarea placeholder="Sua questão"></textarea>
                            <button type="submit" class="site-btn"><a href="creditopessoalrapido.html">Enviar</a></button>
                        </form>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <!-- Contact Section End -->

    <!-- Contact Address Begin -->
    <div class="contact-address">
        <div class="container">
            <div class="contact__address__text">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact__address__item">
                            <h4>Agência Sede de Maputo</h4>
                            <p>
                            Av. 25 de Setembro nº 270,  Edificio Time Square Office Park, Bloco nº4, R/C, loja nº7
                            </p>
                            <span>(+258) 824731609/844867035</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact__address__item">
                            <h4>Agência da Manhiça</h4>
                            <p> Estrada Nacional Nº 1; Vila da Manhiça, Maputo , Moçambique</p>
                             <span>(+258) 824731609/844867035</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact__address__item">
                            <h4>Head office</h4>
                            <p>Av. 25 de Setembro número 270, Edificio Time Square Office Park, Bloco Quatro, R/C, loja nº7</p>
                            <span>(+258) 824731609/844867035</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    
   <div class="container">
            
                
      <!--Google map-->
      <div id="map-container-google-12" class="z-depth-1-half map-container-7" style="height: 400px">
        <!--<iframe src="https://maps.google.com/maps?q=Miami&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"
          style="border:0" allowfullscreen></iframe>-->
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d235.8564279910469!2d32.58188126318923!3d-25.97868726687673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-PT!2smz!4v1600891970670!5m2!1spt-PT!2smz" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>         

           
        </div> 
    

    
  </div>
</template>

<script>
import MBreadCrumb from "@/components/Layout/BreadCrumb";

export default {
  name: "Contact",
  components: { MBreadCrumb }
}
</script>

<style scoped>

</style>